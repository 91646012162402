import * as React from 'react'
import PropTypes from 'prop-types'
// TODO: This causes problems - look at
import './styles.scss'

const CheckList = ({ data }) => {
    return (
        <ul className="c-checklist">
            {data.map((item, index) => {
                return <li key={index}>{item}</li>
            })}
        </ul>
    )
}

CheckList.propTypes = {
    /**
     * Data
     */
    data: PropTypes.array.isRequired
}

CheckList.defaultProps = {
    data: []
}

export default CheckList
